import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <div>
      <br />
      <h1 className="text-center display-4 fw-bolder">Ошибка 404. Страница не найдена.</h1>
      <p className="text-center display-5 fw-bolder">Такой страницы на нашем сайте нет.</p>
      <p className="text-center display-5 fw-bolder">Зато у нас есть много других страниц.</p>
      <p className="text-center display-5 fw-bolder">Попробуйте перейти на главную страницу</p>
      <div class="d-grid gap-2 col-6 mx-auto text-center">
        <Link className="navbar-item text-reset text-center background-dark-gray-ozdo" activeClassName="callout text-center" to="/catalog">

          <p className="text-center display-5">Перейти на главную страницу</p>
        </Link>
        <p className="text-center display-5 fw-bolder">или в каталог продукции</p>
        <Link className="navbar-item text-reset text-center background-dark-gray-ozdo" activeClassName="callout text-center" to="/catalog">
        <p className="text-center display-5">Перейти в каталог продукции</p>
        </Link>
      </div>
      <br /><br /><br /><br /><br />
    </div>
  </Layout>
);

export default NotFoundPage;
